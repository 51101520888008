import { BuilderElement } from '@builder.io/react';

export const defaultBlocks: Array<BuilderElement> = [
  {
    '@type': '@builder.io/sdk:Element',
    responsiveStyles: {
      large: {}
    },
    component: {
      name: 'Text',
      options: {
        text: '<p>This is a default block, replace with new content.</p>'
      }
    }
  }
];
