import { memo } from 'react';

import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

/**
 * Register the ModalLink component.
 */
const register = (): void => {
  Builder.registerComponent(memo(dynamic(() => import('./index'))), {
    name: 'ModalLink',
    static: true,
    inputs: [
      {
        name: 'text',
        type: 'richText',
        defaultValue: ''
      },
      {
        name: 'modalTitle',
        type: 'string',
        defaultValue: '',
        helperText: 'The title of the modal.'
      },
      {
        name: 'contentID',
        type: 'string',
        required: true,
        defaultValue: '',
        helperText:
          'The id of the content, if looking up a page should be the url, if not should be the id target.'
      },
      {
        name: 'contentType',
        type: 'string',
        defaultValue: 'fragment',
        enum: ['page', 'fragment'],
        helperText:
          'The content type of the requested content. Should be a page or a fragment. Fragment corresponds to the section model.'
      }
    ]
  });
};

export default register;
