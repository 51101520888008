import { Input } from '@builder.io/sdk';
import type { Gravity } from '@/react/components/content/template-data';

/**
 * Gravity for the image.
 * See {@link Gravity} for the available values, these should always match.
 */
export const gravity = {
  name: 'gravity',
  type: 'text',
  defaultValue: 'auto',
  enum: [
    'auto',
    'north',
    'north_east',
    'north_west',
    'south',
    'south_east',
    'south_west',
    'east',
    'west',
    'center'
  ]
} satisfies Input;
