import { Input } from '@builder.io/sdk';

import { alignment } from '../parts/alignment';
import { buttonVariant } from '../parts/buttonVariant';

/**
 * The text transform options for the button.
 */
export enum ButtonTextTransform {
  None = 'none',
  Uppercase = 'uppercase',
  Lowercase = 'lowercase'
}

export const link = [
  {
    name: 'text',
    type: 'text',
    defaultValue: 'Shop Now'
  },
  {
    name: 'link',
    type: 'text',
    defaultValue: '/'
  },
  {
    name: 'newTab',
    friendlyName: 'Open in new tab',
    type: 'boolean',
    defaultValue: false
  },
  {
    name: 'fullWidth',
    friendlyName: 'Full Width',
    type: 'boolean',
    defaultValue: false
  },
  {
    name: 'title',
    friendlyName: 'Title',
    type: 'string',
    defaultValue: ''
  },
  {
    name: 'ariaLabel',
    friendlyName: 'Aria Label',
    type: 'string',
    defaultValue: ''
  },
  {
    name: 'styling',
    type: 'object',
    defaultValue: {
      style: 'none',
      marginX: 'none',
      marginY: 'none',
      alignment: 'left'
    },
    subFields: [
      buttonVariant,
      alignment,
      {
        name: 'margin',
        type: 'text',
        defaultValue: '0',
        helperText:
          'The link/button outer margins. Example: 10px or 10px 0 0 0.'
      },
      {
        name: 'height',
        type: 'text',
        defaultValue: 'auto',
        helperText: 'The link/button height. Example: 32px.'
      },
      {
        name: 'fontSize',
        type: 'text',
        defaultValue: '16px',
        helperText: 'The link/button font size. Example: 16px.'
      },
      {
        name: 'textTransform',
        type: 'text',
        defaultValue: ButtonTextTransform.None,
        enum: Object.values(ButtonTextTransform).map((value) => {
          return { label: value.toUpperCase(), value };
        }),
        helperText: 'The link/button text transform style. Default is none.'
      },
      {
        name: 'width',
        type: 'text',
        defaultValue: '150px',
        helperText:
          'The link/button width by percent or pixels. Example: 50% or 100px.'
      }
    ]
  }
] satisfies Array<Input>;
