/**
 * When fetching content from builder.io we need to specify the model type.
 * Most content will come in the form of a page or a section, but any custom data model that we
 * want to query will have to be added here.
 * @see https://www.builder.io/c/docs/models-pages
 */
export enum ModelTarget {
  Section = 'section',
  Page = 'page'
}
