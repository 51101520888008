import { memo } from 'react';

import { Builder, withChildren } from '@builder.io/react';
import dynamic from 'next/dynamic';

import { uiImages } from '@/react/components/content/registry/data';

/**
 * Register the Wrapper component.
 */
const register = (): void => {
  const component = memo(dynamic(() => import('./index')));
  const componentWithChildren = withChildren(component);

  Builder.registerComponent(componentWithChildren, {
    name: 'Wrapper',
    static: true,
    canHaveChildren: true,
    inputs: [
      {
        name: 'blocks',
        type: 'array',
        hideFromUI: true
      },
      {
        name: 'display',
        type: 'string',
        enum: ['default', 'desktop', 'mobile'],
        defaultValue: 'default'
      }
    ],
    defaultChildren: [],
    image: uiImages.sectionImage
  });
};

export default register;
