import { memo } from 'react';

import dynamic from 'next/dynamic';

import { Builder } from '@builder.io/react';
import { uiImages } from '@/react/components/content/registry/data';

/**
 * The fragment parser component is used to display markup and text from outside sources.
 */
const register = (): void => {
  Builder.registerComponent(memo(dynamic(() => import('./index'))), {
    name: 'FragmentParser',
    friendlyName: 'HTML Fragment',
    inputs: [
      { name: 'string', type: 'richText' },
      { name: 'renderAsString', type: 'boolean' }
    ],
    image: uiImages.cubeImage
  });
};

export default register;
