import { memo } from 'react';

import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

import { block, uiImages } from '@/react/components/content/registry/data';

/**
 * Register the TextBlock component.
 */
const register = (): void => {
  Builder.registerComponent(memo(dynamic(() => import('./index'))), {
    name: 'TextBlock',
    inputs: [block],
    image: uiImages.cubeImage
  });
};

export default register;
