import { memo } from 'react';

import { Builder, withChildren } from '@builder.io/react';
import dynamic from 'next/dynamic';

import { uiImages } from '@/react/components/content/registry/data';
import { sliderSettings } from '../../registry/data/parts/sliderSettings';
import { defaultBlocks } from '../../registry/data/parts/defaultBlock';

/**
 * Register the ContentSlider component.
 */
const register = (): void => {
  const component = memo(dynamic(() => import('./index')));
  const componentWithChildren = withChildren(component);

  Builder.registerComponent(componentWithChildren, {
    name: 'ContentSlider',
    friendlyName: 'Content Slider',
    inputs: [{ name: 'title', type: 'richText' }, sliderSettings],
    image: uiImages.cubeImage
  });
};

export default register;
