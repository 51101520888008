import { Nullable } from '@/type-utils';

import { BuilderContent, builder } from '@builder.io/sdk';

import { RequestConflictError } from '@/utils/errors';

import Service from '@/services/Service';
import LoggerService from '@/services/isomorphic/LoggerService';
import ConfigurationService, {
  type Config
} from '@/services/isomorphic/ConfigurationService';

import { ContentType } from '@/services/isomorphic/ContentService';
import { exhaustiveGuard } from '@/utils/function-utils';
import siteCached from '@/services/utils/siteCached';
import type { IBuilderContentOptions } from './schemas/IBuilderContentOptions';
import { ModelTarget } from './schemas';

import BuilderServiceMock from './BuilderServiceMock';
import { EnvironmentService } from '../../EnvironmentService';

/**
 * Content service to normalize and handle the builder.io integration.
 * The BuilderService should be used to fetch content directly from
 * builder.io using the SDK.
 * It should also be used to provide utility methods that allow the ContentService
 * to interact with builder.io indirectly.
 * @see https://www.builder.io/c/docs/developers
 */
export class BuilderService extends Service {
  private isInitialized = false;

  /**
   * The builderio config.
   * @returns A `Config<'builderio'>`.
   */
  @siteCached
  private get config(): Config<'builderio'> {
    const config = ConfigurationService.getConfig('builderio');
    return config;
  }

  /**
   * The content config.
   * @returns A `Config<'content'>`.
   */
  @siteCached
  private get contentConfig(): Config<'content'> {
    const config = ConfigurationService.getConfig('content');
    return config;
  }

  /**
   * Initializes the builder service once.
   */
  public initBuilder(): void {
    if (this.isInitialized) {
      return;
    }

    builder.init(this.builderPublicKey);
    this.isInitialized = true;
  }

  /**
   * The builder public key from the configuration.
   * @returns The builder public key.
   */
  public get builderPublicKey(): string {
    const builderPublicKey =
      ConfigurationService.getConfig('builderio').getSetting(
        'builderPublicKey'
      ).value;

    return builderPublicKey;
  }

  /** Initializes the content service. */
  public constructor() {
    super();
  }

  /**
   * Returns the builder {@link ModelTarget} for a given {@link ContentType}.
   * @param contentType - The content type from the {@link ContentType} enum.
   * @returns A {@link ModelTarget} for the given contentType.
   * @throws An error if the contentType is not found.
   */
  public getBuilderContentType(contentType: ContentType): ModelTarget {
    switch (contentType) {
      case ContentType.Page:
        return ModelTarget.Page;
      case ContentType.Fragment:
        return ModelTarget.Section;
      default:
        return exhaustiveGuard(
          contentType,
          `No Builder.io content type found for ${contentType}.`
        );
    }
  }

  /**
   * Gets content when given a set of options including a tag, a urlPath, and the model name.
   * @param options - Options object for getting content from builder.io.
   * @returns The content for the page, this content is given to the builder.io SDK
   * in order to be displayed on the page. If it doesn't find anything, it returns null.
   */
  public async getContentByModel(
    options: IBuilderContentOptions
  ): Promise<Nullable<BuilderContent>> {
    const isEnabled = this.config.getSetting('enabled').value;
    if (!isEnabled) {
      LoggerService.warn(
        `Cannot get content from builder.io as it is not enabled.`
      );
      return null;
    }

    this.initBuilder();

    const cacheSeconds =
      this.contentConfig.getSetting('dynamicRevalidate').value;

    // If the content is a page then the id is the urlPath, otherwise then it is representative of the
    // id custom target.
    const isPage = options.type === ModelTarget.Page;

    try {
      const builderContent = await builder
        .get(options.type, {
          userAttributes: {
            urlPath: isPage ? options.id : undefined,
            id: !isPage ? options.id : undefined,
            tag: options.tag ?? undefined
          },
          options: {
            locale: 'Default',
            cacheSeconds
          }
        })
        .toPromise();

      return (builderContent as BuilderContent) ?? null;
    } catch (error) {
      LoggerService.error(
        new RequestConflictError('Error fetching builder content', {
          cause: error as Error
        })
      );
    }

    return null;
  }
}

export default BuilderService.withMock(
  new BuilderServiceMock(BuilderService)
) as unknown as BuilderService;
