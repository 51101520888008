import { Input } from '@builder.io/sdk';

export const videoSettings = {
  name: 'videoSettings',
  type: 'object',
  advanced: true,
  defaultValue: {
    poster: '',
    autoplay: false,
    controls: true,
    loop: false,
    muted: true
  },
  subFields: [
    {
      name: 'poster',
      type: 'cloudinaryImageEditor'
    },
    {
      name: 'autoplay',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'controls',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'loop',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'muted',
      type: 'boolean',
      defaultValue: true
    }
  ]
} satisfies Input;
