import { memo } from 'react';

import dynamic from 'next/dynamic';

import { Builder } from '@builder.io/react';
import {
  buttonVariant,
  uiImages
} from '@/react/components/content/registry/data';

/**
 * Register the Button component.
 */
const register = (): void => {
  Builder.registerComponent(memo(dynamic(() => import('./index'))), {
    name: 'Button',
    inputs: [
      {
        friendlyName: 'Text',
        name: 'children',
        type: 'text',
        defaultValue: 'Shop Now'
      },
      buttonVariant,
      {
        name: 'fullWidth',
        friendlyName: 'Full Width',
        type: 'boolean',
        defaultValue: false
      },
      {
        name: 'title',
        friendlyName: 'Title',
        type: 'string',
        defaultValue: ''
      },
      {
        name: 'ariaLabel',
        friendlyName: 'Aria Label',
        type: 'string',
        defaultValue: ''
      }
    ],
    image: uiImages.cubeImage
  });
};

export default register;
