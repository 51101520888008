import { memo } from 'react';

import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

import {
  block,
  uiImages,
  media
} from '@/react/components/content/registry/data';
import {
  defaultMediaValues,
  mediaSubfields
} from '@/react/components/content//registry/data/components/media';

/**
 * Register the SwappableImage component.
 */
const register = (): void => {
  Builder.registerComponent(memo(dynamic(() => import('./index'))), {
    name: 'SwappableImage',
    friendlyName: 'Swappable Image',
    inputs: [
      block,
      {
        name: 'textColor',
        type: 'string',
        enum: ['light', 'dark'],
        defaultValue: 'light'
      },
      {
        name: 'hoverTextColor',
        type: 'string',
        enum: ['light', 'dark'],
        defaultValue: 'dark'
      },
      media,
      {
        name: 'hoverMedia',
        type: 'object',
        subFields: mediaSubfields,
        defaultValue: {
          defaultMedia: defaultMediaValues,
          desktopMedia: defaultMediaValues
        }
      }
    ],
    image: uiImages.cubeImage
  });
};

export default register;
