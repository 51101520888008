import { memo } from 'react';

import { Builder, withChildren } from '@builder.io/react';
import dynamic from 'next/dynamic';

import { uiImages } from '@/react/components/content/registry/data';
import { defaultBlocks } from '../../registry/data/parts/defaultBlock';

/**
 * Register the ParallaxColumns component.
 */
const register = (): void => {
  const component = memo(dynamic(() => import('./index')));
  const componentWithChildren = withChildren(component);

  Builder.registerComponent(componentWithChildren, {
    name: 'ParallaxColumns',
    inputs: [
      {
        name: 'columns',
        type: 'array',
        subFields: [
          {
            name: 'blocks',
            type: 'array',
            hideFromUI: true,
            defaultValue: defaultBlocks
          }
        ],
        defaultValue: [{ blocks: defaultBlocks }, { blocks: defaultBlocks }]
      }
    ],
    image: uiImages.sectionImage
  });
};

export default register;
