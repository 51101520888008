import { Constructor } from '@/type-utils';
import { mocked } from '@/configs';

import type { BuilderContent } from '@builder.io/sdk';

import MockService, { MockState, ServiceMock } from '../../MockService';
import Service from '../../../Service';

import type { BuilderService } from './BuilderService';

import { MOCK_BUILDER_CONTENT } from './mocks/MOCK_BUILDER_CONTENT';
import { ModelTarget } from './schemas';

const initialState = {};

/**
 * Mock for the BuilderService.
 */
export default class BuilderServiceMock extends ServiceMock<BuilderService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): BuilderService {
    // This is a proxy of the Content service and only contains the public members.
    return MockService.getMockOf(this.service) as unknown as BuilderService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<BuilderService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Service): void {
    const mockEnabled: boolean = mocked.ContentService;
    MockService.mockService(
      mockEnabled,
      service as Constructor<BuilderService>,
      {
        initBuilder: async () => {},
        getBuilderContentType: () => {
          return ModelTarget.Page;
        },
        getContentByModel: async (): Promise<BuilderContent> => {
          return Promise.resolve(MOCK_BUILDER_CONTENT);
        }
      } as Partial<BuilderService>,
      {},
      this.state
    );
  }
}
