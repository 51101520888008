import { memo } from 'react';

import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

import {
  block,
  uiImages,
  media
} from '@/react/components/content/registry/data';

/**
 * Register the ContentBlock component.
 */
const register = (): void => {
  Builder.registerComponent(memo(dynamic(() => import('./index'))), {
    name: 'ContentBlock',
    inputs: [block, media],
    image: uiImages.cubeImage
  });
};

export default register;
