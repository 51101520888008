import { memo } from 'react';

import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

import { uiImages } from '@/react/components/content/registry/data';

/**
 * Register the ContentProductTile component.
 */
const register = (): void => {
  Builder.registerComponent(memo(dynamic(() => import('./index'))), {
    name: 'ContentProductTile',
    image: uiImages.cubeImage,
    noWrap: true,
    inputs: [
      {
        name: 'productID',
        type: 'string'
      },
      {
        name: 'variant',
        type: 'string',
        defaultValue: 'default',
        enum: ['default', 'recommendation', 'cart']
      },
      {
        name: 'isSlideTile',
        type: 'boolean'
      },
      {
        name: 'promotionText',
        type: 'string'
      }
    ]
  });
};

export default register;
