import { Builder } from '@builder.io/react';
import { Input } from '@builder.io/sdk';

import SkeletonLoader from '@/react/components/utility/SkeletonLoader';

import {
  link,
  media,
  uiImages
} from '@/react/components/content/registry/data';
import dynamic from 'next/dynamic';
import { memo } from 'react';

const linkCTA = [
  ...link,
  {
    name: 'isCTA',
    friendlyName: 'Is CTA',
    type: 'boolean',
    defaultValue: false
  },
  {
    name: 'settings',
    type: 'object',
    defaultValue: {
      positionX: 'left',
      positionY: 'bottom'
    },
    subFields: [
      {
        name: 'positionX',
        type: 'text',
        defaultValue: 'left',
        enum: ['left', 'right', 'center']
      },
      {
        name: 'positionY',
        type: 'text',
        defaultValue: 'top',
        enum: ['top', 'bottom', 'center']
      }
    ]
  }
] satisfies Array<Input>;

/**
 * Register the ParllaxBlock component.
 */
const register = (): void => {
  Builder.registerComponent(
    memo(
      dynamic(() => import('./index'), {
        loading: () => <SkeletonLoader style={{ minHeight: '60vh' }} />
      })
    ),
    {
      name: 'LinkedMedia',
      inputs: [
        media,
        {
          name: 'link',
          type: 'object',
          subFields: linkCTA,
          defaultValue: {
            text: 'Shop Now',
            link: '/',
            isCTA: false,
            settings: {
              positionX: 'left',
              pasitionY: 'bottom'
            }
          }
        }
      ],
      image: uiImages.cubeImage,
      noWrap: false
    }
  );
};

export default register;
