import { memo } from 'react';

import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

import { media, uiImages } from '@/react/components/content/registry/data';

/**
 * Register the ContentMedia component.
 */
const register = (): void => {
  Builder.registerComponent(memo(dynamic(() => import('./index'))), {
    name: 'ContentMedia',
    image: uiImages.cubeImage,
    inputs: [media]
  });
};

export default register;
