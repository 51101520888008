import { memo } from 'react';

import { Builder, withChildren } from '@builder.io/react';
import dynamic from 'next/dynamic';

import { uiImages } from '@/react/components/content/registry/data';
import { defaultBlocks } from '../../registry/data/parts/defaultBlock';

/**
 * Register the LandingContent component.
 */
const register = (): void => {
  const component = memo(dynamic(() => import('./index')));
  const componentWithChildren = withChildren(component);

  Builder.registerComponent(componentWithChildren, {
    name: 'LandingContent',
    static: true,
    canHaveChildren: true,
    inputs: [
      {
        name: 'text',
        type: 'richText',
        defaultValue: ''
      }
    ],
    defaultChildren: [],
    image: uiImages.sectionImage
  });
};

export default register;
