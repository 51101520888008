import { memo } from 'react';

import dynamic from 'next/dynamic';

import { Builder } from '@builder.io/react';
import { uiImages } from '@/react/components/content/registry/data';

/**
 * The Breadcrumbs component is used to display a list of breadcrumbs.
 */
const register = (): void => {
  Builder.registerComponent(memo(dynamic(() => import('./index'))), {
    name: 'HeaderBreadcrumbs',
    inputs: [
      {
        name: 'breadcrumbs',
        type: 'list',
        subFields: [
          { name: 'link', type: 'string' },
          { name: 'displayValue', type: 'string' }
        ]
      }
    ],
    image: uiImages.cubeImage
  });
};

export default register;
