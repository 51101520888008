import { Input } from '@builder.io/sdk';

/**
 * The Arrow Variant enum.
 */
export enum ArrowVariant {
  Angle = 'angle',
  AngleLight = 'angleLight',
  Arrow = 'arrow',
  Caret = 'caret'
}

export const sliderSettingsDefaultValues = {
  slidesPerView: 1,
  mobileSlidesPerView: 1,
  height: 'auto',
  loop: false,
  slideDirection: 'horizontal',
  centeredSlides: false,
  watchOverflow: true,
  navigationEnabled: false,
  navigationOptions: {
    inset: false,
    arrowVariant: ArrowVariant.Arrow
  },
  pagination: {
    enabled: true,
    inset: false,
    direction: 'horizontal',
    clickable: true
  }
};

export const sliderSettings = {
  name: 'sliderSettings',
  type: 'object',
  defaultValue: sliderSettingsDefaultValues,
  subFields: [
    {
      name: 'slidesPerView',
      type: 'number',
      defaultValue: 1
    },
    {
      name: 'mobileSlidesPerView',
      type: 'number',
      defaultValue: 1
    },
    {
      name: 'height',
      type: 'text',
      enum: ['auto', '100%', '50vh'],
      defaultValue: 'auto'
    },
    {
      name: 'loop',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'slideDirection',
      type: 'text',
      defaultValue: 'horizontal',
      enum: ['horizontal', 'vertical']
    },
    {
      name: 'centeredSlides',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'watchOverflow',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'navigationEnabled',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'navigationOptions',
      type: 'object',
      subFields: [
        {
          name: 'inset',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'arrowVariant',
          type: 'text',
          defaultValue: ArrowVariant.Arrow,
          enum: Object.values(ArrowVariant).map((value) => {
            return value;
          })
        }
      ]
    },
    {
      name: 'pagination',
      type: 'object',
      advanced: true,
      subFields: [
        {
          name: 'enabled',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'inset',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'direction',
          type: 'text',
          defaultValue: 'horizontal',
          enum: ['horizontal', 'vertical']
        },
        {
          name: 'clickable',
          type: 'boolean',
          defaultValue: true
        }
      ]
    }
  ]
} satisfies Input;
