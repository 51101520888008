import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

import {
  block,
  uiImages,
  media,
  alignment
} from '@/react/components/content/registry/data';
import { memo } from 'react';

/**
 * Register the StyleBlock component.
 */
const register = (): void => {
  Builder.registerComponent(memo(dynamic(() => import('./index'))), {
    name: 'StyleBlock',
    inputs: [
      block,
      {
        name: 'style',
        type: 'string',
        defaultValue: 'none',
        enum: [
          'go-do',
          'image-tile',
          'image-tile-tall',
          'image-tile-text-bottom',
          'none'
        ]
      },
      alignment,
      media
    ],
    image: uiImages.cubeImage
  });
};

export default register;
