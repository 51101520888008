import { Input } from '@builder.io/sdk';

import { aspectRatio } from '../parts/aspectRatio';
import { videoSettings } from '../parts/videoSettings';
import { gravity } from '../parts/gravity';

/**
 * The hover animation style for the media item.
 */
export enum HoverStyle {
  Grow = 'grow',
  OpacityGrow = 'opacity-grow',
  None = 'none'
}

export const defaultMediaValues = {
  aspectRatio: '1:1',
  videoSettings: {
    autoplay: false,
    loop: true,
    muted: true,
    controls: true
  },
  gravity: 'auto',
  hoverStyle: HoverStyle.None
};

export const mediaSubfields = [
  {
    name: 'defaultMedia',
    type: 'object',
    defaultValue: {
      aspectRatio: '1:1',
      videoSettings: defaultMediaValues.videoSettings,
      featured: false,
      gravity: 'auto',
      hoverStyle: defaultMediaValues.hoverStyle
    },
    subFields: [
      {
        name: 'item',
        type: 'cloudinaryImageEditor'
      },
      aspectRatio,
      gravity,
      {
        name: 'hoverStyle',
        type: 'text',
        enum: Object.values(HoverStyle).map((value) => {
          return { label: value.toUpperCase(), value };
        }),
        defaultValue: HoverStyle.None,
        advanced: true,
        helperText: 'The hover animation style for the media item.'
      },
      videoSettings,
      {
        name: 'featured',
        type: 'boolean',
        defaultValue: false,
        description: 'This increases the quality of the image or video.',
        advanced: true
      }
    ]
  },
  {
    name: 'desktopMedia',
    type: 'object',
    defaultValue: {
      aspectRatio: '1:1',
      videoSettings: defaultMediaValues.videoSettings,
      featured: false,
      gravity: 'auto'
    },
    hoverStyle: defaultMediaValues.hoverStyle,
    subFields: [
      {
        name: 'item',
        type: 'cloudinaryImageEditor'
      },
      aspectRatio,
      gravity,
      {
        name: 'hoverStyle',
        type: 'text',
        enum: Object.values(HoverStyle).map((value) => {
          return { label: value.toUpperCase(), value };
        }),
        defaultValue: HoverStyle.None,
        advanced: true,
        helperText: 'The hover animation style for the media item.'
      },
      videoSettings,
      {
        name: 'featured',
        type: 'boolean',
        defaultValue: false,
        description: 'This increases the quality of the image or video.',
        advanced: true
      }
    ]
  }
] as Array<Input>;

export const media = {
  name: 'media',
  type: 'object',
  defaultValue: {
    defaultMedia: defaultMediaValues,
    desktopMedia: defaultMediaValues
  },
  subFields: mediaSubfields
} satisfies Input;
