import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';
import { uiImages } from '../../registry/data';

/** Register the Countdown component. */
const register = (): void => {
  Builder.registerComponent(
    dynamic(() => import('./index')),
    {
      name: 'Countdown',
      inputs: [
        {
          name: 'headline',
          type: 'richText',
          required: true
        },
        {
          name: 'targetDate',
          type: 'date',
          required: true,
          helperText: 'Date/Time will be set in Pacific Standard Time (PST)'
        },
        {
          name: 'precision',
          type: 'string',
          enum: ['days', 'hours', 'minutes', 'seconds'],
          defaultValue: 'seconds',
          required: true
        }
      ],
      image: uiImages.cubeImage,
      noWrap: false
    }
  );
};

export default register;
