import { Builder } from '@builder.io/react';

/**
 * Registers the menu items for the builder.io editor.
 * This allows you to group components in the editor.
 * @see https://www.builder.io/c/docs/sidebar-menu
 */
export const registerMenu = (): void => {
  Builder.register('insertMenu', {
    name: 'Core Components',
    items: [
      { name: 'ContentBlock' },
      { name: 'TextBlock' },
      { name: 'ContentMedia' },
      { name: 'Wrapper' },
      { name: 'ModalLink' }
    ]
  });

  Builder.register('insertMenu', {
    name: 'Main Components',
    items: [
      { name: 'SwappableImage' },
      { name: 'ParallaxBlock' },
      { name: 'ParallaxColumns' },
      { name: 'StyleBlock' },
      { name: 'ScrollEffectBlock' },
      { name: 'ContentSlider' },
      { name: 'Button' },
      { name: 'FragmentParser' },
      { name: 'Breadcrumbs' },
      { name: 'LinkedMedia' },
      { name: 'Countdown' }
    ]
  });

  Builder.register('insertMenu', {
    name: 'Page Components',
    items: [{ name: 'HeaderBreadcrumbs' }, { name: 'LandingContent' }]
  });

  Builder.register('insertMenu', {
    name: 'Personalization Components',
    items: [{ name: 'ContentExperience' }]
  });

  Builder.register('insertMenu', {
    name: 'Product Components',
    items: [{ name: 'ContentProductTile' }]
  });
};
