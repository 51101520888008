import { Input } from '@builder.io/sdk';

import { ButtonVariant } from '@/react/components/core-ui/Button';

export const buttonVariant = {
  name: 'variant',
  type: 'text',
  defaultValue: 'primary',
  enum: [
    'primary',
    'secondary',
    'tertiary',
    'text',
    'link',
    'danger',
    'decorative-link',
    'transparent-dark',
    'transparent-light'
  ] as Array<ButtonVariant>
} satisfies Input;
