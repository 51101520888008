import { Input } from '@builder.io/sdk';

import { link } from './link';
import { blockStyling } from '../parts/blockStyling';

export const block = {
  name: 'block',
  type: 'object',
  defaultValue: {
    headline: '',
    text: '',
    links: [],
    linkAlignment: 'column',
    styling: {
      style: 'none',
      marginX: 'none',
      marginY: 'none',
      alignment: 'left'
    }
  },
  subFields: [
    {
      name: 'headline',
      type: 'richText'
    },
    {
      name: 'text',
      type: 'richText'
    },
    {
      name: 'links',
      type: 'list',
      subFields: link
    },
    {
      name: 'linkAlignment',
      type: 'string',
      enum: ['column', 'row']
    },
    blockStyling
  ]
} satisfies Input;
