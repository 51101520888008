import { Input } from '@builder.io/sdk';

import { alignment } from './alignment';

export const blockStyling = {
  name: 'styling',
  type: 'object',
  helperText: 'Pick from styling options to customize the block.',
  defaultValue: {
    style: 'none',
    marginX: 'none',
    marginY: 'none',
    alignment: 'left'
  },
  subFields: [
    {
      name: 'style',
      type: 'string',
      defaultValue: 'none',
      enum: ['none'],
      helperText:
        'Choose a style for the block. These styles are predefined and should be overwritten by the below fields.'
    },
    {
      name: 'backgroundColor',
      type: 'color'
    },
    {
      name: 'textColor',
      type: 'color'
    },
    {
      name: 'marginX',
      type: 'string',
      enum: ['none', 'small', 'medium', 'large'],
      defaultValue: 'none'
    },
    {
      name: 'marginY',
      type: 'string',
      enum: ['none', 'small', 'medium', 'large'],
      defaultValue: 'none'
    },
    alignment
  ]
} satisfies Input;
