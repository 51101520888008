import { memo } from 'react';

import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

import { uiImages, link } from '@/react/components/content/registry/data';
import { ScrollEffectDirection } from './ScrollEffectDirection';

const defaultGradient = {
  direction: 180,
  colorStops: [
    {
      color: 'black',
      stopPercentage: 10
    },
    {
      color: 'white'
    },
    {
      color: 'black',
      stopPercentage: 90
    }
  ]
};

/**
 * Register the ScrollEffectBlock component.
 */
const register = (): void => {
  Builder.registerComponent(
    memo(dynamic(() => import(/* webpackChunkName: "index" */ './index'))),
    {
      name: 'ScrollEffectBlock',
      friendlyName: 'Scroll Effect Block',
      inputs: [
        {
          name: 'text',
          friendlyName: 'Text',
          type: 'string',
          defaultValue: 'Lorem ipsum odor amet, consectetuer adipiscing elit.'
        },
        {
          name: 'link',
          friendlyName: 'Link',
          type: 'object',
          subFields: link
        },
        {
          name: 'backgroundColor',
          friendlyName: 'Background Color',
          type: 'color',
          defaultValue: 'black'
        },
        {
          name: 'animate',
          type: 'boolean',
          friendlyName: 'Animate',
          defaultValue: false,
          helperText:
            'Will make the gradient act as a background that appears to move behind the text.'
        },
        {
          name: 'direction',
          type: 'string',
          friendlyName: 'Direction',
          enum: Object.values(ScrollEffectDirection).map((value) => {
            return { label: value.toUpperCase(), value };
          }),
          defaultValue: ScrollEffectDirection.Vertical,
          showIf: (options) => options.get('animate') === true
        },
        {
          name: 'gradient',
          friendlyName: 'Gradient',
          type: 'object',
          defaultValue: defaultGradient,
          subFields: [
            {
              name: 'direction',
              type: 'number',
              friendlyName: 'Direction',
              defaultValue: 180,
              helperText: 'The direction, in degrees, of the gradient.'
            },
            {
              name: 'colorStops',
              friendlyName: 'Color Stops',
              helperText: 'The color stops of the gradient.',
              type: 'list',
              defaultValue: defaultGradient.colorStops,
              subFields: [
                {
                  name: 'color',
                  friendlyName: 'Color',
                  type: 'color',
                  helperText: 'The color of the stop.'
                },
                {
                  name: 'stopPercentage',
                  friendlyName: 'Stop Percentage',
                  type: 'number',
                  helperText: 'The stop percentage of the color.'
                }
              ]
            }
          ]
        }
      ],
      image: uiImages.cubeImage
    }
  );
};

export default register;
